@charset "utf-8"

@import "../../styles/lib/variables.sass"
@import "../../styles/lib/mixins.sass"




























































































































































.blog-page
  .article
    margin-bottom: m(3)
    transition: opacity 1s ease
    .image
      margin-bottom: m(2)
    .author
      +font('bold')
    h2
      margin-bottom: m(1)
  .pagination
    display: flex
    align-items: baseline
    justify-content: center
    .placeholder
      margin: 0 3px
      +font("bold")
    .button-elem
      margin: 0 3px          
  .spinner-elem
    display: none
    top: m(3)
    left: 50%
    transform: translateX(-50%)
  &.is-loading
    .spinner-elem
      display: block
